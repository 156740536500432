.discover-section {
	border-right: 15px solid #FFD249;
	min-height: 22vh;
	width: 100%;
	margin: 5px 0 5px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.first-row {
	display: flex;
	justify-content: center;
}

.second-row {
	display: flex;
	justify-content: space-around;
}


/* light color: ffe596 */
/* grey: 676767 */