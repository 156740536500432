.App {
	text-align: center;
	z-index: 1;
	margin-left: auto;
	overflow: hidden;
	background-color: #F2F2F2;
  }
  
  .App-logo {
	height: 40vmin;
	pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
	.App-logo {
	  animation: App-logo-spin infinite 20s linear;
	}
  }
  
  .App-header {
	background-color: white;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	position: relative;
	margin-left: 0;
	transition: margin-left 0.3s ease-out;
  }
  
  .App-header.side-drawer-open {
	background-color: white;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	position: relative;
	margin-left: 35vw;
	transition: margin-left 0.3s ease-out;
  }
  
  .App-link {
	color: #61dafb;
  }
  
  @keyframes App-logo-spin {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }

.title-container {
	display: flex;
	justify-content: center;
}

.dashboard-button {
	position: absolute;
	right: 0;
}
  