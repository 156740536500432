.card-container {
	display: flex;
}

.left-nav-menu {
	position: fixed;
	width: 15vw;
	height: 100%;
	background-color: #141414;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-top: 20px;
	color: #F2F2F2;
	box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
}

.dashboard {
	position: fixed;
	left: 15vw;
	width: 84vw;
	padding: 10px;
	background-color: #F2F2F2;
	height: 100vh;
	overflow-y: scroll;
}

.profile-header-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.button-container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 15px;
	width: 500px;
}

.stories-container {
	padding: 15px;
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
}

.student-stories-container {
	padding: 15px;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
}

.story-card {
	min-width: 150px;
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
}
