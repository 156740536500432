body { 
   margin: 0; 
   padding: 0; 
}
.side-drawer { 
   height: 100vh;
   background: white;
   position: fixed;
   top: 0;
   left: 0;
   width: 35vw;
   z-index: 1;
   box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
   transform: translateX(-100%);
   transition: transform 0.3s ease-out;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.side-drawer.open {
   transform: translateX(0);
}

.title-section {
	background-color: #FFD249;
	height: 10vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px 10px 10px 10px;
}

/* .scroll {
	direction: rtl;
	overflow-y: scroll;
	height: 100%;
	padding-left: 17px;
} */

.body-section {
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: rgba(0, 0, 0, 1);
	color: white;
	font-size: 14px;
	overflow-y: auto;
	overflow-x: hidden;
}

.question-answer-container {
	display: flex;
	flex-direction: column;
}

.question-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px 10px 10px;
	min-height: 50px;
}

.down-arrow-icon {
	padding: 0 10px 0 10px;
	font-size: x-large;
	cursor: pointer;
}

.minus-icon {
	padding: 0 10px 0 10px;
	font-size: large;
	cursor: pointer;
}

.answer-visible {
	color: #F2F2F2;
	visibility: visible;
	background-color: rgba(53, 52, 52, 1);
	min-height: 50px;
	width: 33vw !important;
	display: flex;
	padding: 10px 10px 10px 10px;
	align-items: flex-start;
}

.answer-hidden {
	visibility: hidden;
	height: 0px !important;
	width: 0px !important;
	border: none !important;
	padding: 0 !important;
}

.footer-section {
	height: 10vw;
	background-color: rgba(53, 52, 52, 1);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 10px 10px 15px;
}

.edit-footer-section {
	height: 10vw;
	background-color: rgba(53, 52, 52, 1);
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 10px 10px 10px 15px;
}