.define-section {
	border-right: 15px solid #4976FF;
	min-height: 22vh;
	width: 100%;
	margin: 5px 0 5px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.first-row {
	display: flex;
	justify-content: center;
	padding: 10px 0 10px 0;
}

.second-row {
	display: flex;
	justify-content: space-around;
	padding: 10px 0 10px 0;
}

.third-row {
	display: flex;
	justify-content: space-around;
	padding: 10px 0 10px 0;
}

.fourth-row {
	display: flex;
	justify-content: center;
	padding: 10px 0 10px 0;
}
