.modal {
	/* display: none; Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
	background-color: #fefefe;
	margin: 15% auto; /* 15% from the top and centered */
	padding: 20px;
	border: 1px solid #888;
	border-radius: 15px;
	width: 60%; /* Could be more or less, depending on screen size */
}

.form-style {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.form-element {
	padding: 10px 0 10px 0;
	width: 75%;
}

.professor-name-container {
	display: flex;
}

.professor-search-container {
	display: flex;
	justify-content: space-around;
}
